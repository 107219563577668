import type { AggregatorBestRoute } from '@libs/aggregators';
import { useState, useEffect } from 'react';

export function useAggregatorPrice(route?: AggregatorBestRoute) {
  const [price, setPrice] = useState<[priceA: number | undefined, priceB: number | undefined]>([
    undefined,
    undefined,
  ]);

  useEffect(() => {
    if (!route) return;

    const fetchPrice = async () => {
      try {
        const prices = await route.getPrices();
        setPrice([prices[route.from], prices[route.to]]);
      } catch {
        setPrice([undefined, undefined]);
      }
    };

    fetchPrice();
    const timer = setInterval(fetchPrice, 4_000);
    return () => clearInterval(timer);
  }, [route?.from, route?.to, route?.name]);

  return price;
}
