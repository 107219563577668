import http from 'src/services/http';
import { defineModel } from 'foca';
import { hideDataConfig } from 'src/configs/hide';

export interface TurbosPoolTickItem {
  id: string;
  index: number;
  initialized: false;
  liquidity_net: string;
  liquidity_gross: string;
  fee_growth_outside_a: string;
  fee_growth_outside_b: string;
}
export interface TurbosPoolRewardItem {
  type: string;
  fields: {
    emissions_per_second: string;
    growth_global: string;
    id: {
      id: string;
    };
    manager: string;
    vault: string;
    vault_coin_type: string;
  };
}
export interface TurbosPoolItem {
  id: number;
  coin_a: string;
  coin_b: string;
  deploy_time_ms: string;
  fee: string;
  fee_growth_global_a: string;
  fee_growth_global_b: string;
  fee_protocol: string;
  liquidity: string;
  max_liquidity_per_tick: string;
  protocol_fees_a: string;
  protocol_fees_b: string;
  sqrt_price: string;
  tick_current_index: number;
  tick_spacing: string;
  unlocked: boolean;
  pool_id: string;
  type: string;
  coin_symbol_a: string;
  coin_symbol_b: string;
  coin_type_a: string;
  coin_type_b: string;
  fee_type: string;
  add_2_percent_depth: string;
  reduce_2_percent_depth: string;
  ticks?: TurbosPoolTickItem[];
  reward_infos: TurbosPoolRewardItem[];
  reward_last_updated_time_ms: string;
  apr: number;
  fee_apr: number;
  reward_apr: number;
  apr_percent: number;
  volume_24h_usd: number;
  liquidity_usd: number;
  category: 'lsd' | 'meme' | 'stable' | 'gamefi' | 'fun' | null;
  created_at: Date;
  updated_at: Date;
  fee_24h_usd: number;
  coin_a_liquidity_usd: number;
  coin_b_liquidity_usd: number;
  flag: number;
  is_vault: boolean;
  top1_pool?: boolean;
}

export interface PoolSwapRoute extends TurbosPoolItem {
  a_to_b: boolean;
}

const initialState: {
  array: Record<string, TurbosPoolItem[]>;
  single: Record<string, TurbosPoolItem>;
  total: number;
  page: number;
  pageSize: number;
} = {
  array: {},
  single: {},
  total: 0,
  page: 1,
  pageSize: 1000,
};

export const poolModel = defineModel('pools', {
  initialState,
  methods: {
    async getPools(
      params: {
        orderBy: string;
        category: string;
        symbol: string;
        includeRisk: boolean;
        coinTypes?: string;
        page?: number;
        pageSize?: number;
      },
      key: string,
    ) {
      const pools = await http.get<{ list: TurbosPoolItem[]; total: number }>('/pools/v2', {
        params: {
          page: 1,
          pageSize: 1000,
          ...params,
        },
        cache: {
          maxAge: 20_000,
        },
      });
      const result = pools.list.filter((pool) => !hideDataConfig.includes(pool.pool_id));
      this.setState((state) => {
        state.array[key] = result;
        state.total = pools.total;
        state.page = params.page || 1;
        state.pageSize = params.pageSize || 200;
        this._updateSingle(state, result);
      });
      return result;
    },
    async getPoolByIds(pools: string[], key: string) {
      // Prepare
      this.setState((state) => {
        state.array[key] = pools
          .map((poolId) => state.single[poolId])
          .filter(Boolean) as TurbosPoolItem[];
      });
      const result = await http.get<TurbosPoolItem[]>('/pools/ids', {
        params: { ids: pools, returnTicks: false },
      });
      this.setState((state) => {
        state.array[key] = result;
        this._updateSingle(state, result);
      });
      return result;
    },
    async getBaseRoute(
      coinTypeA: string,
      coinTypeB: string,
      amountSpecifiedIsInput: boolean,
      amount: string,
    ) {
      try {
        const result = await http.get<
          {
            pools: PoolSwapRoute[];
            weight: number;
            type: 'single-hop' | 'double-hop';
          }[]
        >(`/pools/bestRoute/v2`, {
          params: { coinTypeA, coinTypeB, amount, amountSpecifiedIsInput },
        });
        return result;
      } catch {
        return [];
      }
    },
    _updateSingle(state: typeof initialState, result: TurbosPoolItem[]) {
      result.forEach((pool) => {
        state.single[pool.pool_id] = {
          ticks: state.single[pool.pool_id]?.ticks,
          ...pool,
        };
      });
    },
  },
});
