import { K7Aggregator } from './7k.provider';
import { AftermathAggregator } from './aftermath.provider';
import type { Aggregator } from './aggregator';
import { OkxAggregator } from './okx.provider';

export const aggregators = {
  'aftermath': new AftermathAggregator(),
  '7k': new K7Aggregator(),
  'okx': new OkxAggregator(),
} satisfies Record<string, Aggregator>;

export * from './aggregator';
