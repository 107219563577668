import { turbosSdk } from '@libs/turbos-sdk';
import { type TurbosCoinItem } from '@models/coin.model';
import { localCoinModel } from '@models/local-coin.model';
// import { localCoinModel } from '@models/local-coin.model';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

export const useSearchCoins = (coins: TurbosCoinItem[], searchName: string) => {
  const [searchCoins, setSearchCoins] = useState<TurbosCoinItem[]>([]);
  const getSearchCoins = async () => {
    let resultCoins: TurbosCoinItem[] = coins;

    if (searchName) {
      const isCoinType = /^0x([A-Za-z\d]+)::([A-Za-z\d_]+)::([A-Za-z\d_]+)$/.test(searchName);
      if (isCoinType) {
        resultCoins = coins.filter((coin) => {
          return coin['type'] === searchName;
        });
        if (resultCoins.length < 1) {
          const coinMetadata = await turbosSdk.provider.getCoinMetadata({ coinType: searchName });
          if (coinMetadata) {
            const newCoin = {
              id: Date.now(),
              name: coinMetadata.name,
              type: searchName,
              symbol: coinMetadata.symbol,
              decimals: coinMetadata.decimals,
              logo_url: coinMetadata.iconUrl!,
              coingecko_id: '',
              in_quote_list: false,
              is_stable: false,
              in_pool: false,
              is_popular: false,
              is_hot: false,
              category_id: 1,
              faucet_amount: '',
              created_at: new Date(),
              updated_at: new Date(),
              category: {
                badge_url: '',
                id: 1,
                name: '',
              },
              pyth_id: '',
              flag: 0,
              cross_chain: null,
            } as TurbosCoinItem;
            resultCoins = [newCoin];
            localCoinModel.append(newCoin);
          }
        }
      } else {
        resultCoins = coins.filter((coin) => {
          return coin['symbol'].toLowerCase().indexOf(searchName.toLowerCase()) > -1;
        });
      }
    }
    setSearchCoins(resultCoins);
  };

  useDeepCompareEffect(() => {
    getSearchCoins();
  }, [searchName, coins]);

  return {
    searchCoins,
  };
};
