import Button from '@ui/button';
import { type FC, memo } from 'react';
import styles from './swap-submit.module.scss';
import { Decimal } from 'turbos-clmm-sdk';
import { useBalance } from '@hooks/use-balances';
import { useCoin } from '@hooks/use-coins';
import { turbosSdk } from 'src/services/turbos-sdk';
import { useComputed, useModel } from 'foca';
import { swapCoinModel } from '@models/swap-coin.model';
import type { BestSwapRouterType } from '@hooks/use-swap-route';
import { useAddress } from '@hooks/use-address';
import { walletConnectModel } from '@models/wallet-connect.model';
import { useTransaction } from '@hooks/use-transaction';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { TransactionStatus } from '@constants/transaction-status';
import { globalSettingModel } from '@models/global-setting.model';
import { transactionModel } from '@models/transaction.model';
import { useInMsafeWallet } from '@hooks/use-in-msafe-wallet';
import Lottie from 'lottie-react';
import TradedJSON from '@assets/lottie/Traded.json';
import type { AggregatorBestRoute } from '@libs/aggregators';
// import { useImpact } from '@hooks/use-impact';

interface OwnProps {
  fetchingBestRoute: boolean;
  bestRoutes: BestSwapRouterType[] | undefined;
  aggregatorRoute: AggregatorBestRoute | undefined;
  insufficientLiquidity: boolean;
  amountIn: string | undefined;
  amountOut: string | undefined;
  amountSpecifiedIsInput: boolean;
  onTransacted(): void;
}

const SwapSubmit: FC<OwnProps> = (props) => {
  const {
    fetchingBestRoute,
    bestRoutes,
    aggregatorRoute,
    insufficientLiquidity,
    amountIn,
    amountOut,
    amountSpecifiedIsInput,
    onTransacted,
  } = props;

  const inMsafeWallet = useInMsafeWallet();

  const { coinTypeA, coinTypeB } = useModel(swapCoinModel);
  const { status: transactionStatus } = useModel(transactionModel);
  const transact = useTransaction('swap');
  const slippage = useComputed(globalSettingModel.realSlippage);
  const address = useAddress();
  const balanceA = useBalance(coinTypeA);
  const coinA = useCoin(coinTypeA);
  const coinB = useCoin(coinTypeB);
  // const impact = useImpact(bestRoutes);

  let msg = 'Trade';
  let disabled = false;
  let loading = false;
  let clickEvent: (() => void) | undefined;

  // const ratio = coinA?.is_stable && coinB?.is_stable ? 1 : 10;
  if (fetchingBestRoute) {
    msg = 'Fetching best price';
    loading = true;
    disabled = true;
  } else if (!address) {
    msg = 'Connect Wallet';
    disabled = false;
    clickEvent = walletConnectModel.toggleDialog;
  } else if (!coinTypeA || !coinTypeB) {
    msg = 'Select token';
    disabled = true;
  } else if (bestRoutes && !bestRoutes.length && !aggregatorRoute) {
    msg = `Route doesn't exist!`;
    disabled = true;
  } else if (insufficientLiquidity) {
    msg = 'Insufficient Liquidity';
    disabled = true;
  } else if (coinA && amountIn && (!balanceA || new Decimal(amountIn).greaterThan(balanceA))) {
    msg = `Not enough ${coinA.symbol}`;
    disabled = true;
  } else if (aggregatorRoute && coinA && coinB) {
    loading = transactionStatus === TransactionStatus.confirm;

    if (aggregatorRoute) {
      loading = transactionStatus === TransactionStatus.confirm;

      clickEvent = async () => {
        const succeed = await transact(
          <>
            Traded{' '}
            <b>
              {bigNumberToReadable(amountIn, coinA.decimals)} {coinA.symbol}
            </b>{' '}
            for{' '}
            <b>
              {bigNumberToReadable(amountOut, coinB.decimals)} {coinB.symbol}
            </b>
          </>,
          async () => {
            return aggregatorRoute.aggregator.buildTransaction(
              aggregatorRoute,
              new Decimal(slippage).div(100).toString(),
              address,
            );
          },
        );

        succeed && onTransacted();
      };
    }
  } else if (
    bestRoutes &&
    bestRoutes.length > 0 &&
    coinA &&
    coinB &&
    balanceA &&
    amountIn &&
    amountOut
  ) {
    loading = transactionStatus === TransactionStatus.confirm;
    clickEvent = async () => {
      const succeed = await transact(
        <>
          Traded{' '}
          <b>
            {bigNumberToReadable(amountIn, coinA.decimals)} {coinA.symbol}
          </b>{' '}
          for{' '}
          <b>
            {bigNumberToReadable(amountOut, coinB.decimals)} {coinB.symbol}
          </b>
        </>,
        async () => {
          return turbosSdk.trade.swap({
            routes: bestRoutes.map(({ swapResult, nextTickIndex }) => ({
              pool: swapResult!.pool,
              a2b: swapResult!.a_to_b,
              nextTickIndex,
            })),
            coinTypeA: coinA.type,
            coinTypeB: coinB.type,
            address,
            amountA: amountIn,
            amountB: amountOut,
            amountSpecifiedIsInput,
            slippage,
            deadline: inMsafeWallet ? 60 * 60 * 1000 : undefined,
          });
        },
        {
          successTip: (
            <>
              <Lottie animationData={TradedJSON} autoplay loop />
            </>
          ),
        },
        undefined,
        true,
      );

      succeed && onTransacted();
    };
  } else {
    disabled = true;
  }

  return (
    <Button
      fullWidth
      type="highlight"
      size="large"
      disabled={disabled}
      className={styles.button}
      loading={loading}
      onClick={clickEvent}
    >
      {msg}
    </Button>
  );
};

export default memo(SwapSubmit);
