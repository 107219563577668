import { useCoin } from '@hooks/use-coins';
import { type FC, memo } from 'react';
import { Decimal } from 'turbos-clmm-sdk';
import { formatDigitUnit } from '@utils/format-digit-unit';
import { useAggregatorPrice } from '@hooks/use-aggregator-price';
import type { AggregatorBestRoute } from '@libs/aggregators';
import { usePrice } from '@hooks/use-price';

interface OwnProps {
  coinType: string;
  decimals: number | undefined;
  amountIn?: string;
  className?: string;
  aggregatorRoute: AggregatorBestRoute | undefined;
}

const CoinAPrice: FC<OwnProps> = ({ amountIn, className, aggregatorRoute, coinType, decimals }) => {
  const aggregatorCoinA = useCoin(aggregatorRoute?.from);
  const [aggregatorPriceA] = useAggregatorPrice(aggregatorRoute);

  const price = usePrice(coinType);

  if (aggregatorCoinA && aggregatorPriceA && amountIn) {
    const value = new Decimal(aggregatorPriceA).mul(amountIn).div(10 ** aggregatorCoinA.decimals);
    return <div className={className}>${formatDigitUnit(value)}</div>;
  }

  if (!price || !amountIn || decimals === undefined) return null;

  return (
    <div className={className}>
      ${formatDigitUnit(new Decimal(price.price).mul(amountIn).div(10 ** decimals))}
    </div>
  );
};

export default memo(CoinAPrice);
