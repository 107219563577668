import TurbosLink from '@pages/deepbook-v2-swap/turbos-link';
import { type FC } from 'react';
import styles from './swap-native.module.scss';

const SwapNative: FC = () => {
  return (
    <div className={styles.step}>
      <p>Suggested pools</p>

      <ul className={styles.items}>
        <li>
          <TurbosLink poolId="0x13490343cab8e8669dccb4cb1a08f7ea090e553c629868b1dad1760027299b12" />
        </li>
        <li>
          <TurbosLink poolId="0xd2a3915f5a8d81032fc5472cbda5899627431a4ce204a80a913f4f7b8f8190b6" />
        </li>
        <li>
          <TurbosLink poolId="0x770010854059edf1dd3d49a97f3054c39b870ec708fe2f408e30a8ef4724caef" />
        </li>
        <li>
          <TurbosLink poolId="0x77f786e7bbd5f93f7dc09edbcffd9ea073945564767b65cf605f388328449d50" />
        </li>
      </ul>
    </div>
  );
};

export default SwapNative;
