import { useAggregatorPrice } from '@hooks/use-aggregator-price';
import { useCoin } from '@hooks/use-coins';
import type { AggregatorBestRoute } from '@libs/aggregators';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { type FC, memo, type ReactNode } from 'react';
import { Decimal } from 'turbos-clmm-sdk';
import styles from './trade-fees.module.scss';

interface OwnProps {
  aggregatorRoute: AggregatorBestRoute;
}

const TradeFeesForAggregator: FC<OwnProps> = ({ aggregatorRoute }) => {
  const [priceA, priceB] = useAggregatorPrice(aggregatorRoute);
  const coinA = useCoin(aggregatorRoute.from);
  const coinB = useCoin(aggregatorRoute.to);

  let $feeA: ReactNode = null;
  if (coinA && priceA && aggregatorRoute.tradeFeeIn) {
    const feePrice = new Decimal(aggregatorRoute.tradeFeeIn).mul(priceA).toString();
    $feeA = (
      <div>
        <span>
          {bigNumberToReadable(aggregatorRoute.tradeFeeIn, coinA.decimals)}&nbsp;{coinA.symbol}
        </span>
        <span>
          &nbsp;({feePrice ? `~\$${bigNumberToReadable(feePrice, coinA.decimals)}` : '-'})
        </span>
      </div>
    );
  }

  let $feeB: ReactNode = null;
  if (coinB && priceB && aggregatorRoute.tradeFeeOut) {
    const feePrice = new Decimal(aggregatorRoute.tradeFeeOut).mul(priceB).toString();
    $feeA = (
      <div>
        <span>
          {bigNumberToReadable(aggregatorRoute.tradeFeeOut, coinB.decimals)}&nbsp;{coinB.symbol}
        </span>
        <span>
          &nbsp;({feePrice ? `~\$${bigNumberToReadable(feePrice, coinB.decimals)}` : '-'})
        </span>
      </div>
    );
  }

  if (!$feeA && !$feeB) return null;

  return (
    <>
      <span>Trade Fees</span>
      <div className={styles.wrapper}>
        {$feeA}
        {$feeB}
      </div>
    </>
  );
};

export default memo(TradeFeesForAggregator);
