import turbosIcon from '@assets/images/aggregator/turbos.svg';
import k7Icon from '@assets/images/aggregator/7k.svg';
// import okxIcon from '@assets/images/aggregator/okx.svg';
import aftermathIcon from '@assets/images/aggregator/aftermath.svg';

export const aggregatorMeta = <const>[
  {
    key: 'turbos',
    icon: turbosIcon,
    title: 'Turbos Aggregator',
  },
  {
    key: 'aftermath',
    icon: aftermathIcon,
    title: 'Aftermath',
  },
  {
    key: '7k',
    icon: k7Icon,
    title: '7K',
  },
  // {
  //   key: 'okx',
  //   icon: okxIcon,
  //   title: 'okx',
  // },
];
