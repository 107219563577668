export const transactionErrorMap: Record<
  string,
  Record<string, string | { title: string; description: string }>
> = {
  swap_router: {
    '2': 'Swap request timed out. Please try initiating the swap again.',
    '3': 'Your transaction failed due to slippage. Please adjust your slippage tolerance to complete the swap.',
    '4': { title: 'Slippage is low', description: 'Please try to increase the slippage.' },
    '5': { title: 'Slippage is low', description: 'Please try to increase the slippage.' },
  },
  position_manager: {
    '8': 'Transaction request timed out. Please try again.',
    '5': { title: 'Slippage is low', description: 'Please try to increase the slippage.' },
  },
  pool: {
    '2': 'Your transaction failed due to slippage. Please adjust your slippage tolerance to complete the swap.',
    '9': 'Your transaction failed due to slippage. Please adjust your slippage tolerance to complete the swap.',
    '10': 'Your transaction failed due to slippage. Please adjust your slippage tolerance to complete the swap.',
    '19': 'Your transaction failed due to slippage. Please adjust your slippage tolerance to complete the swap.',
    '20': { title: 'Slippage is low', description: 'Please try to increase the slippage.' },
  },
  settle: {
    '0': { title: 'Slippage is low', description: 'Please try to increase the slippage.' },
  },
};
