import {
  Aggregator,
  type AggregatorBestRoute,
  type AggregatorBestRouteOptions,
} from '@libs/aggregators/aggregator';
import type { Transaction } from '@mysten/sui/dist/cjs/transactions';
import { Aftermath, type RouterCompleteTradeRoute } from 'aftermath-ts-sdk';

export class AftermathAggregator extends Aggregator<RouterCompleteTradeRoute> {
  protected readonly aftermath = new Aftermath('MAINNET');

  override getName(): string {
    return 'Aftermath';
  }

  override async findBestRoute(
    data: AggregatorBestRouteOptions,
  ): Promise<AggregatorBestRoute<RouterCompleteTradeRoute>> {
    const route = await this.aftermath.Router().getCompleteTradeRouteGivenAmountIn({
      coinInType: data.coinTypeA,
      coinInAmount: BigInt(data.amountIn),
      coinOutType: data.coinTypeB,
    });

    const from = this.transformSuiAddress(route.coinIn.type);
    const to = this.transformSuiAddress(route.coinOut.type);

    return {
      from,
      to,
      amountIn: route.coinIn.amount.toString(),
      amountOut: route.coinOut.amount.toString(),
      origin: route,
      aggregator: this,
      name: this.getName(),
      getPrices: this.combineGetPrices(from, to),
    };
  }

  async findBestRouteByAmountOut(
    data: Omit<AggregatorBestRouteOptions, 'amountIn'> & { amountOut: string },
  ): Promise<AggregatorBestRoute<RouterCompleteTradeRoute>> {
    const route = await this.aftermath.Router().getCompleteTradeRouteGivenAmountOut({
      coinInType: data.coinTypeA,
      coinOutAmount: BigInt(data.amountOut),
      coinOutType: data.coinTypeB,
      slippage: Number(data.slippage),
    });

    const from = this.transformSuiAddress(route.coinIn.type);
    const to = this.transformSuiAddress(route.coinOut.type);

    return {
      from,
      to,
      amountIn: route.coinIn.amount.toString(),
      amountOut: route.coinOut.amount.toString(),
      tradeFeeIn: route.coinIn.tradeFee.toString(),
      tradeFeeOut: route.coinOut.tradeFee.toString(),
      origin: route,
      aggregator: this,
      name: this.getName(),
      getPrices: this.combineGetPrices(from, to),
    };
  }

  override async buildTransaction(
    route: AggregatorBestRoute<RouterCompleteTradeRoute>,
    slippage: string,
    address: string,
  ): Promise<Transaction> {
    return this.aftermath.Router().getTransactionForCompleteTradeRoute({
      completeRoute: route.origin,
      slippage: Number(slippage),
      walletAddress: address,
    });
  }

  protected override async getPrices(from: string, to: string): Promise<Record<string, number>> {
    const result = await this.aftermath.Prices().getCoinsToPrice({
      coins: [from, to],
    });
    return Object.fromEntries(
      Object.entries(result)
        .filter(([_k, v]) => v > 0)
        .map(([k, v]) => {
          return [this.transformSuiAddress(k), v];
        }),
    );
  }
}
