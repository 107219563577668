import { type FC, memo, useState, useCallback, useEffect } from 'react';
import SwapHeader from './swap-header';
import styles from './index.module.scss';
import SwapSection from './swap-section';
import { useModel } from 'foca';
import { swapCoinModel } from '@models/swap-coin.model';
import SwapExchange from './swap-exchange';
import SwapSubmit from './swap-submit';
import { useSwapRoute } from '@hooks/use-swap-route';
import RouteInfo from '../swap-route';
import CoinAPrice from './coin-a-price';
import CoinBPrice from './coin-b-price';
import PythPrice from './pyth-price';
import { useHash } from 'react-use';
import { findLocationSearch } from '@utils/location-search';
import { globalSettingModel } from '@models/global-setting.model';
import Block from '@components/block';
import { useCoin } from '@hooks/use-coins';

const Swap: FC = () => {
  const { coinTypeA, coinTypeB } = useModel(swapCoinModel);
  const coinA = useCoin(coinTypeA);
  const coinB = useCoin(coinTypeB);
  const [amount, setAmount] = useState<string>();
  const [amountSpecifiedIsInput, setAmountSpecifiedIsInput] = useState(false);
  const {
    fetchingBestRoute,
    bestRoute,
    amountIn,
    amountOut,
    insufficientLiquidity,
    aggregatorRoute,
  } = useSwapRoute(amount, amountSpecifiedIsInput);
  const hash = useHash();
  const aggregatorName = useModel(globalSettingModel, (state) => state.aggregatorName);

  const disableQuoteInput = aggregatorName !== 'turbos' && aggregatorName !== 'aftermath';

  const handleTransacted = useCallback(() => {
    setAmount(undefined);
    // TO clear both input value
    setAmountSpecifiedIsInput((prev) => !prev);
  }, []);

  const handleSetInput = useCallback((value?: string) => {
    setAmount(value);
    setAmountSpecifiedIsInput(true);
  }, []);

  const handleSetOutput = useCallback((value?: string) => {
    setAmount(value);
    setAmountSpecifiedIsInput(false);
  }, []);

  const handleExchange = () => {
    swapCoinModel.exchange();
    if (disableQuoteInput) {
      setAmountSpecifiedIsInput(true);
      setAmount(amountSpecifiedIsInput ? amountOut : amountIn);
    } else {
      setAmountSpecifiedIsInput((prev) => !prev);
    }
  };

  useEffect(() => {
    if (coinTypeA && coinTypeB) {
      window.location.hash = `/trade?input=${coinTypeA}&output=${coinTypeB}`;
    }
  }, [coinTypeA, coinTypeB]);

  useEffect(() => {
    const _hash = hash[0].replace(/.*\?/, '');
    const input = findLocationSearch(_hash, 'input');
    const output = findLocationSearch(_hash, 'output');

    input && swapCoinModel.updateA(input);
    output && swapCoinModel.updateB(output);
  }, [hash[0]]);

  return (
    <>
      <SwapHeader />
      <div className={styles.wrapper}>
        <SwapSection
          key={coinTypeA}
          label="From"
          coinType={coinTypeA}
          onSelectCoin={swapCoinModel.updateA}
          disableCoins={coinTypeB}
          showMaxAmount={true}
          amount={amountIn}
          inputByUser={amountSpecifiedIsInput}
          onChangeValue={handleSetInput}
          onChangeAmount={setAmount}
          loading={fetchingBestRoute}
        />
        <div className={styles.price_row}>
          <PythPrice coinType={coinTypeA} />
          {fetchingBestRoute && !amountIn ? (
            <Block />
          ) : (
            <CoinAPrice
              decimals={coinA?.decimals}
              coinType={coinTypeA}
              amountIn={amountIn}
              className={styles.coin_price}
              aggregatorRoute={aggregatorRoute}
            />
          )}
        </div>

        <SwapExchange onExchange={handleExchange} />
        <SwapSection
          key={coinTypeB}
          label="To"
          inputDisabled={disableQuoteInput}
          coinType={coinTypeB}
          onSelectCoin={swapCoinModel.updateB}
          disableCoins={coinTypeA}
          showMaxAmount={false}
          amount={amountOut}
          inputByUser={!disableQuoteInput && !amountSpecifiedIsInput}
          onChangeValue={handleSetOutput}
          onChangeAmount={setAmount}
          loading={fetchingBestRoute}
        />
        <div className={styles.price_row}>
          <PythPrice coinType={coinTypeB} />
          {fetchingBestRoute && !amountOut ? (
            <Block />
          ) : (
            <CoinBPrice
              bestRoutes={bestRoute}
              amountIn={amountIn}
              amountOut={amountOut}
              className={styles.coin_price}
              aggregatorRoute={aggregatorRoute}
            />
          )}
        </div>

        <SwapSubmit
          bestRoutes={bestRoute}
          fetchingBestRoute={fetchingBestRoute}
          amountSpecifiedIsInput={amountSpecifiedIsInput}
          insufficientLiquidity={insufficientLiquidity}
          amountIn={amountIn}
          amountOut={amountOut}
          onTransacted={handleTransacted}
          aggregatorRoute={aggregatorRoute}
        />
        {coinA && coinB && (
          <RouteInfo
            coinA={coinA}
            coinB={coinB}
            amountIn={amountIn}
            amountOut={amountOut}
            bestRoutes={bestRoute}
            fetchingBestRoute={fetchingBestRoute}
            amountSpecifiedIsInput={amountSpecifiedIsInput}
            aggregatorRoute={aggregatorRoute}
          />
        )}
      </div>
    </>
  );
};

export default memo(Swap);
