import { type FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './top.module.scss';
// import { swapCoinModel } from '@models/swap-coin.model';
import { Link, useNavigate } from 'react-router-dom';
import walPc1 from '@assets/images/top/wal-pc-1.svg';
import walPc2 from '@assets/images/top/wal-pc-2.svg';
import walPc3 from '@assets/images/top/wal-pc-3.svg';

export const Top: FC = () => {
  const navigator = useNavigate();

  const banners = [
    <div
      className={`${styles.slide} ${styles.lbtc}`}
      onClick={() => {
        navigator(
          '/pools/0x13490343cab8e8669dccb4cb1a08f7ea090e553c629868b1dad1760027299b12/add-liquidity',
        );
      }}
    ></div>,
    <div className={`${styles.slide} ${styles.wal}`}>
      <div className={styles.content}>
        <Link to="/pools/0x9490a13351e13b133bd8a9c309c47568d6fcbfcbfe7aac0e228b710053583081/add-liquidity">
          <img src={walPc3} />
        </Link>
        <Link to="/pools/0x4f519b65c0e394b697d538ae3d6922c10f707a696208ea24cb3a69479aa24528/add-liquidity">
          <img src={walPc2} />
        </Link>
        <Link to="/trade?input=0x2::sui::SUI&output=0x356a26eb9e012a68958082340d4c4116e7f55615cf27affcff209cf0ae544f59::wal::WAL">
          <img src={walPc1} />
        </Link>
      </div>
    </div>,
  ];

  const finalBalances = banners.sort(() => Math.random() - 0.5);
  return (
    <div className={styles.top}>
      <Swiper
        modules={[Autoplay, Pagination]}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
      >
        {finalBalances.map((item, index) => (
          <SwiperSlide key={index}>{item}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
