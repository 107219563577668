import { config } from '@config';
import { defineModel } from 'foca';

export interface TurbosCurrentRpcNode {
  rpc: string;
  rpcKey: keyof typeof config.rpc | 'custom';
}

interface State {
  slippage: string;
  isCustomSlippage: boolean;
  version: string;
  explorerKey: keyof typeof config.explorer_url;
  rpcKey: TurbosCurrentRpcNode['rpcKey'];
  limit: {
    slippage: string;
    isCustomSlippage: boolean;
  };
  liquidity: {
    slippage: string;
    isCustomSlippage: boolean;
  };
  aggregatorName: 'turbos' | 'aftermath' | '7k' | 'okx';
}

export const globalSettingModel = defineModel('global-setting', {
  initialState: <State>{
    slippage: 'Auto',
    isCustomSlippage: false,
    explorerKey: 'suivision',
    version: '0.0.0',
    rpcKey: 'shinami',
    limit: {
      slippage: '1',
      isCustomSlippage: false,
    },
    liquidity: {
      slippage: '0.1',
      isCustomSlippage: false,
    },
    aggregatorName: 'turbos',
  },
  reducers: {
    setCustomSlippage(state, slippage: string) {
      state.slippage = slippage;
      state.isCustomSlippage = true;
    },
    setAggregator(state, aggregator: State['aggregatorName']) {
      state.aggregatorName = aggregator;
    },
    setEnumSlippage(state, slippage: string) {
      state.slippage = slippage;
      state.isCustomSlippage = false;
    },
    setExplorerKey(state, key: keyof typeof config.explorer_url) {
      state.explorerKey = key;
    },
    setRpcKey(state, key: TurbosCurrentRpcNode['rpcKey']) {
      state.rpcKey = key;
    },
    setLimitSlippage(state, slippage: string, isCustomSlippage: boolean) {
      state.limit = {
        slippage,
        isCustomSlippage,
      };
    },
    setLiquiditySlippage(state, slippage: string, isCustomSlippage: boolean) {
      state.liquidity = {
        slippage,
        isCustomSlippage,
      };
    },
  },
  methods: {
    explorerUrlForAccount(address: string) {
      const url =
        config.explorer_url[this.state.explorerKey]?.account || config.explorer_url.suiscan.account;
      return url.replace('{{account}}', address);
    },
    explorerUrlForObject(object: string) {
      const url =
        config.explorer_url[this.state.explorerKey]?.object || config.explorer_url.suiscan.object;
      return url.replace('{{object}}', object);
    },
    explorerUrlForCoin(coin: string) {
      const url =
        config.explorer_url[this.state.explorerKey]?.coin || config.explorer_url.suiscan.coin;
      return url.replace('{{coin}}', coin);
    },
    explorerUrlForTransaction(address: string) {
      const url =
        config.explorer_url[this.state.explorerKey]?.txb || config.explorer_url.suiscan.txb;
      return url.replace('{{txb}}', address);
    },
    init() {
      if (this.state.version !== '0.0.1') {
        this.setState({
          ...this.state,
          slippage: 'Auto',
          isCustomSlippage: false,
          explorerKey: 'suivision',
          version: '0.0.1',
          rpcKey: 'shinami',
        });
      }
    },
  },
  events: {
    onInit() {
      this.init();
    },
  },
  computed: {
    realSlippage() {
      return this.state.slippage === 'Auto' ? '0.5' : this.state.slippage || '0.5';
    },
    liquiditySlippage() {
      return this.state.liquidity.slippage;
    },
  },
});
