import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { type FC, memo } from 'react';
import styles from './exchange-rate.module.scss';
import { Decimal } from 'turbos-clmm-sdk';

import { useRefreshIcon } from '@hooks/use-refresh';
import downIcon from '@assets/images/icons/limit-down.svg';
import type { TurbosCoinItem } from '@models/coin.model';
import Loading from '@ui/loading';

interface OwnProps {
  coinA: TurbosCoinItem;
  coinB: TurbosCoinItem;
  amountIn: string | undefined;
  amountOut: string | undefined;

  toggleShowInfo(value?: boolean): void;
  showInfo: boolean;
  fetchingBestRoute: boolean;
}

const ExchangeRate: FC<OwnProps> = ({
  amountIn,
  amountOut,
  coinA,
  coinB,
  toggleShowInfo,
  showInfo,
  fetchingBestRoute,
}) => {
  const RefreshView = useRefreshIcon();

  let price = 0;
  if (amountOut && amountIn) {
    price = new Decimal(amountOut)
      .mul(10 ** coinA.decimals)
      .div(new Decimal(amountIn).mul(10 ** coinB.decimals))
      .toNumber();
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.best_route}>
        <span>Best Route:&nbsp;</span>

        <div className={styles.best_route_div}>
          1&nbsp;{coinA.symbol}&nbsp;≈&nbsp;
          {fetchingBestRoute || !price ? (
            <Loading size={16} />
          ) : (
            <>
              {bigNumberToReadable(price, 0)}&nbsp;{coinB.symbol}
            </>
          )}
        </div>

        <img
          src={downIcon}
          data-icon
          onClick={() => toggleShowInfo()}
          className={showInfo ? styles.up : ''}
        />
      </div>
      <div className={styles.refresh_view}>{RefreshView}</div>
    </div>
  );
};

export default memo(ExchangeRate);
