import { useEffect, type FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Toast from '@ui/toast';
import Header from '@components/header';
import styles from './index.module.scss';
import Transaction from '@components/transaction';
import Privacy from '@components/privacy';
import { turbosSdk } from '@libs/turbos-sdk';
import { toast } from 'react-toastify';
import { useInitCoins } from '@hooks/use-coins';

const App: FC = () => {
  const { pathname } = useLocation();
  useInitCoins();
  const getCoin = async () => {
    try {
      await turbosSdk.provider.getCoinMetadata({ coinType: '0x2::sui::SUI' });
    } catch (err) {
      toast(
        <div style={{ color: 'red' }}>
          The current node is temporarily unavailable. Please switch to an alternative node to
          ensure uninterrupted service.
        </div>,
        { type: 'error' },
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    getCoin();
  }, []);

  return (
    <>
      <Header />
      <div className={styles.body}>
        <Outlet />
      </div>
      <Toast />
      <Transaction />
      <Privacy />
    </>
  );
};

export default App;
