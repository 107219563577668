import { usePoolPriceAndLiquidity } from '@hooks/use-pool-price-and-liquidity';
import { type PoolSwapRoute } from '@models/pool.model';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { type FC, memo } from 'react';
import { Decimal } from 'turbos-clmm-sdk';

interface OwnProps {
  fee: {
    a2b: boolean;
    symbol: string;
    totalFee: string | undefined;
    feePrice: string;
    decimals: number;
    pool: PoolSwapRoute;
  };
}

const TradeFeesItem: FC<OwnProps> = ({ fee }) => {
  const PPAL = usePoolPriceAndLiquidity(fee.pool);
  let feePrice = '';
  if (PPAL && fee.totalFee) {
    feePrice = new Decimal(fee.totalFee)
      .div(10 ** fee.decimals)
      .mul(fee.a2b ? PPAL.priceA : PPAL.priceB)
      .toString();
  }

  return (
    <div>
      <span>
        {bigNumberToReadable(fee.totalFee, fee.decimals)}&nbsp;
        {fee.symbol}
      </span>
      <span>{<>&nbsp;({feePrice ? `~\$${bigNumberToReadable(feePrice, 0)}` : '-'})</>}</span>
    </div>
  );
};

export default memo(TradeFeesItem);
