import { type FC, memo } from 'react';
import styles from './index.module.scss';
import { Popover, type PopoverProps } from 'antd';

interface OwnProps extends PopoverProps {}

const TurbosPopover: FC<OwnProps> = ({ children, rootClassName, ...props }) => {
  return (
    <Popover
      placement="bottom"
      rootClassName={`${styles.parrot_popover} ${rootClassName}`}
      openClassName={styles.no_animation}
      arrow={false}
      overlayClassName={styles['no-animation-popover']}
      {...props}
    >
      {children}
    </Popover>
  );
};

export default memo(TurbosPopover);
