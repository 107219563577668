import { globalSettingModel } from '@models/global-setting.model';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { Decimal } from 'turbos-clmm-sdk';
import { useComputed } from 'foca';
import { type FC, memo } from 'react';
import type { TurbosCoinItem } from '@models/coin.model';
import Block from '@components/block';

interface OwnProps {
  coinA: TurbosCoinItem;
  coinB: TurbosCoinItem;
  amountIn: string | undefined;
  amountOut: string | undefined;
  amountSpecifiedIsInput: boolean;
  fetchingBestRoute: boolean;
}

const ExpectedAmount: FC<OwnProps> = (props) => {
  const { coinA, coinB, amountIn, amountOut, amountSpecifiedIsInput, fetchingBestRoute } = props;

  const slippage = useComputed(globalSettingModel.realSlippage);

  const amount = amountSpecifiedIsInput
    ? new Decimal(100)
        .minus(slippage)
        .dividedBy(100)
        .mul(amountOut || 0)
    : new Decimal(100)
        .plus(slippage)
        .dividedBy(100)
        .mul(amountIn || 0);
  const decimals = amountSpecifiedIsInput ? coinB.decimals : coinA.decimals;
  const symbol = amountSpecifiedIsInput ? coinB.symbol : coinA.symbol;

  return (
    <>
      <span>{amountSpecifiedIsInput ? 'Minimum Received' : 'Maximum Sold'}</span>
      {fetchingBestRoute ? (
        <Block width={80} />
      ) : (
        <div>
          {bigNumberToReadable(amount, decimals)}&nbsp;{symbol}
        </div>
      )}
    </>
  );
};

export default memo(ExpectedAmount);
