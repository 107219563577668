import type { Transaction } from '@mysten/sui/transactions';
import { throttle } from 'lodash-es';

export interface AggregatorBestRoute<Route extends object = object> {
  origin: Route;
  amountOut: string;
  amountIn: string;
  tradeFeeIn?: string;
  tradeFeeOut?: string;
  from: string;
  to: string;
  aggregator: Aggregator;
  name: string;
  getPrices(): Promise<Record<string, number>>;
}

export interface AggregatorBestRouteOptions {
  amountIn: string;
  coinTypeA: string;
  coinTypeB: string;
  slippage: string;
}

export abstract class Aggregator<Route extends object = object> {
  abstract getName(): string;

  abstract findBestRoute(data: AggregatorBestRouteOptions): Promise<AggregatorBestRoute<Route>>;

  abstract buildTransaction(
    route: AggregatorBestRoute<Route>,
    slippage: string,
    address: string,
  ): Promise<Transaction>;

  protected combineGetPrices(from: string, to: string) {
    return throttle(() => this.getPrices(from, to), 2_000, {
      leading: true,
      trailing: false,
    });
  }

  protected abstract getPrices(from: string, to: string): Promise<Record<string, number>>;

  protected transformSuiAddress(coinType: string) {
    return coinType ===
      '0x0000000000000000000000000000000000000000000000000000000000000002::sui::SUI'
      ? '0x2::sui::SUI'
      : coinType;
  }
}
