import { useCoins, useStableCoinIds } from '@hooks/use-coins';
import type { BestSwapRouterType } from '@hooks/use-swap-route';
import { turbosSdk } from 'src/services/turbos-sdk';
import { Decimal } from 'turbos-clmm-sdk';
import { type FC, memo, useMemo } from 'react';
import styles from './trade-fees.module.scss';
import TradeFeesItem from './trade-fees-item';
import type { PoolSwapRoute } from '@models/pool.model';

interface OwnProps {
  bestRoutes?: BestSwapRouterType[];
}

const TradeFees: FC<OwnProps> = ({ bestRoutes }) => {
  const { coins } = useCoins();
  const stableCoins = useStableCoinIds();

  const fees = useMemo(() => {
    if (!bestRoutes) return;
    return {
      fees: bestRoutes
        .map(({ swapResult, pool, nextTickIndex }) => {
          if (!pool || !swapResult) {
            return;
          }
          const a2b = pool.a_to_b;
          const coinA = coins.find((item) => item.type === pool.coin_type_a)!;
          const coinB = coins.find((item) => item.type === pool.coin_type_b)!;
          const currentTick = stableCoins.includes(
            pool.a_to_b ? pool.coin_type_a : pool.coin_type_b,
          )
            ? 0
            : nextTickIndex;
          const currentPrice = turbosSdk.math
            .tickIndexToPrice(
              currentTick,
              coinA.decimals,
              currentTick === 0 ? coinA.decimals : coinB.decimals,
            )
            .toString();
          const decimals = a2b ? coinA.decimals : coinB.decimals;
          const amount = turbosSdk.math.scaleDown(swapResult.fee_amount, decimals);
          const feePrice = new Decimal(currentPrice).mul(amount).toString();

          return {
            symbol: a2b ? coinA.symbol : coinB.symbol,
            totalFee: swapResult.fee_amount,
            feePrice,
            decimals,
            pool,
            a2b,
            type: a2b ? coinA.type : coinB.type,
          };
        })
        .filter((res) => res),
    };
  }, [bestRoutes, coins, stableCoins]);

  return (
    <>
      <span>Trade Fees</span>
      <div className={styles.wrapper}>
        {fees
          ? fees.fees.map((fee) => {
              if (!fee) return null;
              return (
                <TradeFeesItem
                  key={fee.symbol}
                  fee={
                    fee as {
                      a2b: boolean;
                      symbol: string;
                      totalFee: string | undefined;
                      feePrice: string;
                      decimals: number;
                      pool: PoolSwapRoute;
                    }
                  }
                />
              );
            })
          : null}
      </div>
    </>
  );
};

export default memo(TradeFees);
