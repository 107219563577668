import { useCoin } from '@hooks/use-coins';
import type { BestSwapRouterType } from '@hooks/use-swap-route';
import { turbosSdk } from '@libs/turbos-sdk';
import { Decimal } from 'turbos-clmm-sdk';
import { type FC, memo } from 'react';
import { usePoolPriceAndLiquidity } from '@hooks/use-pool-price-and-liquidity';
import { formatDigitUnit } from '@utils/format-digit-unit';
import { useAggregatorPrice } from '@hooks/use-aggregator-price';
import type { AggregatorBestRoute } from '@libs/aggregators';

interface OwnProps {
  bestRoutes: BestSwapRouterType[] | undefined;
  amountIn?: string;
  amountOut?: string;
  className?: string;
  aggregatorRoute: AggregatorBestRoute | undefined;
}

const CoinBPrice: FC<OwnProps> = ({
  bestRoutes,
  amountIn,
  amountOut,
  className,
  aggregatorRoute,
}) => {
  const firstPool = bestRoutes?.[0]?.pool;
  const lastPool = bestRoutes?.[bestRoutes.length - 1]?.pool;
  const firstCoinA = useCoin(firstPool?.coin_type_a);
  const firstCoinB = useCoin(firstPool?.coin_type_b);
  const lastCoinA = useCoin(lastPool?.coin_type_a);
  const lastCoinB = useCoin(lastPool?.coin_type_b);
  const firstPPAL = usePoolPriceAndLiquidity(firstPool);
  const lastPPAL = usePoolPriceAndLiquidity(lastPool);
  const aggregatorCoinA = useCoin(aggregatorRoute?.from);
  const aggregatorCoinB = useCoin(aggregatorRoute?.to);
  const [aggregatorPriceA, aggregatorPriceB] = useAggregatorPrice(aggregatorRoute);

  let totalPriceB: string | undefined, ratio: string | undefined;

  if (
    aggregatorCoinA &&
    aggregatorCoinB &&
    aggregatorPriceA &&
    aggregatorPriceB &&
    amountOut &&
    amountIn
  ) {
    totalPriceB = turbosSdk.math.scaleDown(
      new Decimal(aggregatorPriceB).mul(amountOut),
      aggregatorCoinB.decimals,
    );

    const totalPriceA = turbosSdk.math.scaleDown(
      new Decimal(aggregatorPriceA).mul(amountIn),
      aggregatorCoinA.decimals,
    );

    const r = new Decimal(totalPriceA).minus(totalPriceB).div(totalPriceA);
    ratio = r.gte(0.0001) ? r.mul(100).neg().toFixed(2) + '%' : '< 0.01%';
  }

  if (lastPPAL && amountOut && lastPool) {
    const priceB = new Decimal(lastPool.a_to_b ? lastPPAL.priceB : lastPPAL.priceA);
    const decimals = lastPool.a_to_b ? lastCoinB!.decimals : lastCoinA!.decimals;
    totalPriceB = turbosSdk.math.scaleDown(priceB.mul(amountOut), decimals);
  }

  if (!totalPriceB) return null;

  if (firstPPAL && amountIn && firstPool) {
    const priceA = new Decimal(firstPool.a_to_b ? firstPPAL.priceA : firstPPAL.priceB);
    const decimals = firstPool.a_to_b ? firstCoinA!.decimals : firstCoinB!.decimals;
    const totalPriceA = turbosSdk.math.scaleDown(priceA.mul(amountIn), decimals);
    const r = new Decimal(totalPriceA).minus(totalPriceB).div(totalPriceA);
    ratio = r.gte(0.0001) ? r.mul(100).neg().toFixed(2) + '%' : '< 0.01%';
  }

  return (
    <div className={className}>
      ${formatDigitUnit(totalPriceB)}
      {!!ratio && <span>&nbsp;({ratio})</span>}
    </div>
  );
};

export default memo(CoinBPrice);
