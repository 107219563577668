import { type CSSProperties, type FC } from 'react';
import styles from './index.module.scss';

interface OwnProps {
  className?: string;
  style?: CSSProperties;
  fullWidth?: boolean;
  width?: number | string;
  height?: number | string;
}

const Block: FC<OwnProps> = ({ className, style, fullWidth, width, height }) => {
  const _style = {
    ...style,
    width: typeof width !== 'number' ? width : width + 'px',
    height: typeof height !== 'number' ? height : height + 'px',
  };

  return (
    <div
      className={`${styles.block} ${className} ${fullWidth ? styles.fullWidth : ''}`}
      style={_style}
    ></div>
  );
};
export default Block;
