import { useImpact } from '@hooks/use-impact';
import type { BestSwapRouterType } from '@hooks/use-swap-route';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { type FC, memo, useMemo } from 'react';

interface OwnProps {
  bestRoutes: BestSwapRouterType[] | undefined;
}

const PriceImpact: FC<OwnProps> = ({ bestRoutes }) => {
  const impact = useImpact(bestRoutes);

  const impactColor = useMemo(() => {
    if (!impact) return;
    if (impact.lessThan(1)) return '#6ADB4E';
    if (impact.greaterThanOrEqualTo(1) && impact.lessThanOrEqualTo(10)) return 'rgb(252,190,86)';
    return 'red';
  }, [impact?.toString()]);

  return (
    <>
      <span>Price Impact</span>
      <div style={{ color: impactColor }}>
        {impact ? (impact.lt(0.01) ? '< 0.01%' : bigNumberToReadable(impact, 0) + '%') : '--'}
      </div>
    </>
  );
};

export default memo(PriceImpact);
