import {
  buildTx,
  getQuote,
  getTokenPrices,
  setSuiClient,
  type QuoteResponse,
} from '@7kprotocol/sdk-ts';
import { turbosSdk } from '@libs/turbos-sdk';
import {
  Aggregator,
  type AggregatorBestRoute,
  type AggregatorBestRouteOptions,
} from './aggregator';
import { Transaction } from '@mysten/sui/transactions';

export class K7Aggregator extends Aggregator<QuoteResponse> {
  constructor() {
    super();
    setSuiClient(turbosSdk.provider);
  }

  override getName(): string {
    return '7K';
  }

  override async findBestRoute(
    data: AggregatorBestRouteOptions,
  ): Promise<AggregatorBestRoute<QuoteResponse>> {
    const quote = await getQuote({
      tokenIn: data.coinTypeA,
      tokenOut: data.coinTypeB,
      amountIn: data.amountIn,
    });

    if (!quote.routes?.length) throw new Error('No route');

    const from = this.transformSuiAddress(quote.tokenIn);
    const to = this.transformSuiAddress(quote.tokenOut);
    return {
      amountIn: quote.swapAmountWithDecimal,
      amountOut: quote.returnAmountWithDecimal,
      from,
      to,
      origin: quote,
      aggregator: this,
      name: this.getName(),
      getPrices: this.combineGetPrices(from, to),
    };
  }

  override async buildTransaction(
    route: AggregatorBestRoute<QuoteResponse>,
    slippage: string,
    address: string,
  ): Promise<Transaction> {
    const { tx } = await buildTx({
      quoteResponse: route.origin,
      accountAddress: address,
      slippage,
      commission: {
        partner: '0xc851a734b97870c41435b06c8254f1ef4cef0d53cfe1bcb0ba21a175b528311e',
        commissionBps: 0,
      },
    });

    return tx;
  }

  protected override async getPrices(from: string, to: string): Promise<Record<string, number>> {
    const result = await getTokenPrices([from, to]);
    return Object.fromEntries(
      Object.entries(result)
        .filter(([_k, v]) => v > 0)
        .map(([k, v]) => {
          return [this.transformSuiAddress(k), v];
        }),
    );
  }
}
