import {
  Aggregator,
  type AggregatorBestRoute,
  type AggregatorBestRouteOptions,
} from '@libs/aggregators/aggregator';
import { axios, type AxiosResponse } from 'foca-axios';
import cryptoJS from 'crypto-js';
import { Transaction } from '@mysten/sui/transactions';

interface OKXBestRoute {
  fromToken: {
    tokenSymbol: string;
    decimal: string;
    tokenUnitPrice: string;
    isHoneyPot: boolean;
    taxRate: string;
    tokenContractAddress: string;
  };
  toToken: {
    tokenSymbol: string;
    decimal: string;
    tokenUnitPrice: string;
    isHoneyPot: boolean;
    taxRate: string;
    tokenContractAddress: string;
  };
  estimateGasFee: string;
  priceImpactPercentage: string;
  fromTokenAmount: string;
  toTokenAmount: string;
  tradeFee: string;
}

export class OkxAggregator extends Aggregator<OKXBestRoute> {
  private readonly suiChainId = '784';
  private readonly key = '4a3c0972-5231-464b-befb-b96214ac9e48';
  private readonly secret = '382A3114DE45154E34C4AF8F6AC76389';
  private readonly projectId = 'ce4be3c611d9ebe9152bd35e2ca273a4';
  private readonly passphrase = 'Hh3976166.';

  protected readonly request = axios.create({
    baseURL: 'https://www.okx.com',
  });

  constructor() {
    super();
    this.request.interceptors.request.use((cfg) => {
      const timestamp = new Date().toISOString();
      let stringToSign = timestamp + cfg.method!.toUpperCase() + cfg.url;
      if (cfg.method === 'post') {
        stringToSign += JSON.stringify(cfg.data);
      }

      const headers = (cfg.headers ||= {});
      headers['Content-Type'] = 'application/json';
      headers['OK-ACCESS-KEY'] = this.key;
      headers['OK-ACCESS-PASSPHRASE'] = this.passphrase;
      headers['OK-ACCESS-PROJECT'] = this.projectId;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      headers['OK-ACCESS-SIGN'] = cryptoJS.enc.Base64.stringify(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        cryptoJS.HmacSHA256(stringToSign, this.secret),
      );
      headers['OK-ACCESS-TIMESTAMP'] = timestamp;
      return cfg;
    });

    this.request.interceptors.response.use(
      (response: AxiosResponse<{ code: string; msg: string }>) => {
        if (response.data.code !== '0') {
          throw new Error(response.data.msg);
        }
        return response;
      },
    );
  }

  override getName(): string {
    return 'okx';
  }

  override async findBestRoute(
    data: AggregatorBestRouteOptions,
  ): Promise<AggregatorBestRoute<OKXBestRoute>> {
    const result = await this.request.get<{ data: OKXBestRoute[] }>(
      '/api/v5/dex/aggregator/quote?' +
        new URLSearchParams({
          chainId: this.suiChainId,
          fromTokenAddress: data.coinTypeA,
          toTokenAddress: data.coinTypeB,
          amount: data.amountIn,
          slippage: data.slippage,
        }).toString(),
    );

    const route = result.data[0]!;
    const from = this.transformSuiAddress(route.fromToken.tokenContractAddress);
    const to = this.transformSuiAddress(route.toToken.tokenContractAddress);
    return {
      amountIn: route.fromTokenAmount,
      amountOut: route.toTokenAmount,
      from,
      to,
      tradeFeeOut: (
        (Number(route.tradeFee) / Number(route.toToken.tokenUnitPrice)) *
        10 ** Number(route.toToken.decimal)
      ).toString(),
      origin: route,
      aggregator: this,
      name: this.getName(),
      getPrices: this.combineGetPrices(from, to),
    };
  }

  override async buildTransaction(
    route: AggregatorBestRoute<OKXBestRoute>,
    slippage: string,
    address: string,
  ): Promise<Transaction> {
    const result = await this.request.get<{ data: { tx: { data: string } }[] }>(
      '/api/v5/dex/aggregator/swap?' +
        new URLSearchParams({
          chainId: this.suiChainId,
          fromTokenAddress: route.from,
          toTokenAddress: route.to,
          amount: route.amountIn,
          slippage: slippage,
          userWalletAddress: address,
        }).toString(),
    );

    return Transaction.from(result.data[0]!.tx.data);
  }

  protected override async getPrices(from: string, to: string): Promise<Record<string, number>> {
    const result = await this.request.post<{ data: { price: string; tokenAddress: string }[] }>(
      '/api/v5/wallet/token/current-price',
      [
        {
          chainIndex: this.suiChainId,
          tokenAddress: from,
        },
        {
          chainIndex: this.suiChainId,
          tokenAddress: to,
        },
      ],
    );

    return Object.fromEntries(
      result.data
        .filter((item) => Number(item.price) > 0)
        .map((item) => {
          return [this.transformSuiAddress(item.tokenAddress), Number(item.price)];
        }),
    );
  }
}
