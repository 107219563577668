import { type FC, memo } from 'react';
import styles from './index.module.scss';
import type { BestSwapRouterType } from '@hooks/use-swap-route';
import { useToggle } from 'react-use';
import CoinPath from './coin-path';
import ExpectedAmount from './expected-amount';
import ExchangeRate from './exchange-rate';
import PriceImpact from './price-impact';
import TradeFees from './trade-fees';
import type { AggregatorBestRoute } from '@libs/aggregators';
import { type TurbosCoinItem } from '@models/coin.model';
import TradeFeesForAggregator from './trade-fees-for-aggregator';
import Block from '@components/block';

interface OwnProps {
  coinA: TurbosCoinItem;
  coinB: TurbosCoinItem;
  amountIn: string | undefined;
  amountOut: string | undefined;
  bestRoutes: BestSwapRouterType[] | undefined;
  aggregatorRoute: AggregatorBestRoute | undefined;
  amountSpecifiedIsInput: boolean;
  fetchingBestRoute: boolean;
}

const RouteInfo: FC<OwnProps> = (props) => {
  const {
    amountIn,
    amountOut,
    bestRoutes,
    amountSpecifiedIsInput,
    fetchingBestRoute,
    aggregatorRoute,
    coinA,
    coinB,
  } = props;

  const [showInfo, toggleShowInfo] = useToggle(true);

  if (!amountIn && !amountOut) {
    return null;
  } else if (!fetchingBestRoute && !bestRoutes && !aggregatorRoute) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <ExchangeRate
        coinA={coinA}
        coinB={coinB}
        amountIn={amountIn}
        amountOut={amountOut}
        toggleShowInfo={toggleShowInfo}
        showInfo={showInfo}
        fetchingBestRoute={fetchingBestRoute}
      />
      {showInfo && (
        <div className={styles.swap_route_info}>
          <div className={styles.swap_info}>
            <CoinPath
              bestRoutes={bestRoutes}
              aggregator={aggregatorRoute?.aggregator}
              fetchingBestRoute={fetchingBestRoute}
            />
          </div>

          <div className={styles.swap_info}>
            <ExpectedAmount
              coinA={coinA}
              coinB={coinB}
              amountIn={amountIn}
              amountOut={amountOut}
              amountSpecifiedIsInput={amountSpecifiedIsInput}
              fetchingBestRoute={fetchingBestRoute}
            />
          </div>

          {fetchingBestRoute ? (
            <div className={styles.swap_info}>
              <span>Price Impact</span>
              <Block width={60} />
            </div>
          ) : (
            <>
              {bestRoutes && (
                <div className={styles.swap_info}>
                  <PriceImpact bestRoutes={bestRoutes} />
                </div>
              )}
            </>
          )}

          {fetchingBestRoute ? (
            <div className={styles.swap_info}>
              <span>Trade Fees</span>
              <Block width={120} />
            </div>
          ) : (
            <div className={styles.swap_info}>
              {!bestRoutes && !aggregatorRoute ? null : aggregatorRoute ? (
                <TradeFeesForAggregator aggregatorRoute={aggregatorRoute} />
              ) : (
                <TradeFees bestRoutes={bestRoutes} />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(RouteInfo);
