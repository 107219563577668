import TurbosPopover from '@ui/popover';
import { type FC, memo, useState, type FocusEventHandler, type ChangeEventHandler } from 'react';
import settingIcon from '@assets/images/icons/trade-setting.svg';
import sliderIcon from '@assets/images/icons/slider.svg';
import closeIcon from '@assets/images/icons/close-circle.svg';
import thunderIcon from '@assets/images/icons/thunder-circle.svg';
import radioIcon from '@assets/images/icons/radio.svg';
import radioSelectedIcon from '@assets/images/icons/radio-selected.svg';

import styles from './index.module.scss';
import { useComputed, useModel } from 'foca';
import { globalSettingModel } from '@models/global-setting.model';
import { Decimal } from 'turbos-clmm-sdk';
import Input from '@ui/input';
import { aggregatorMeta } from '@constants/aggregator-meta';
import { useWindowSize } from 'react-use';

const SelectSlippage: FC = () => {
  const { width } = useWindowSize();
  const [showSetting, setShowSetting] = useState(false);
  const slippage = useComputed(globalSettingModel.realSlippage);

  return (
    <TurbosPopover trigger="hover" content={'Trade settings'} placement="top" arrow={true}>
      <TurbosPopover
        trigger="click"
        open={showSetting}
        content={
          <Setting
            onClose={() => {
              setShowSetting(false);
            }}
          />
        }
        onOpenChange={setShowSetting}
        placement={width < 640 ? 'bottom' : 'rightTop'}
        rootClassName={styles.slippage}
      >
        <div className={styles.button}>
          <span>{slippage}%</span>
          <img src={settingIcon} />
        </div>
      </TurbosPopover>
    </TurbosPopover>
  );
};

const Setting: FC<{ onClose: () => void }> = ({ onClose }) => {
  const {
    slippage,
    isCustomSlippage: isCustom,
    aggregatorName: aggregator,
  } = useModel(globalSettingModel);
  const realSlippage = useComputed(globalSettingModel.realSlippage);
  const [inputValue, setInputValue] = useState(realSlippage);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = Number(e.target.value) >= 0 ? e.target.value : '0.1';
    setInputValue(value);
    globalSettingModel.setCustomSlippage(Number(value).toString());
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    setInputValue(isCustom ? slippage : '');
  };

  return (
    <>
      <div className={styles.setting_title}>
        <span>Trade Settings</span>
        <img src={closeIcon} data-icon onClick={onClose} />
      </div>
      <div className={styles.setting_top}>
        <img src={sliderIcon} data-icon />
        <span>Slippage Tolerance</span>
      </div>

      <div className={styles.setting}>
        <div className={styles.custom}>
          {['Auto', '0.1', '0.5'].map((item) => (
            <span
              key={item}
              className={item === slippage ? styles.selected : ''}
              onClick={() => {
                globalSettingModel.setEnumSlippage(item);
                setInputValue(globalSettingModel.realSlippage());
              }}
            >
              {item}
              {Number.isFinite(Number(item)) && '%'}
            </span>
          ))}
        </div>
        <div className={styles.input_wrapper}>
          <div className={styles.label}>Custom:</div>
          <Input
            type="text"
            value={inputValue}
            placeholder={realSlippage}
            onChange={handleChange}
            onBlur={handleBlur}
            suffix="%"
          />
        </div>
      </div>
      {isCustom && new Decimal(slippage).lte(0) && (
        <div className={styles.error_tip}>Enter a valid number greater than 0</div>
      )}
      {isCustom && new Decimal(slippage).gt(10) && (
        <div className={styles.error_tip}>Your transaction may be frontrun</div>
      )}
      <div className={styles.setting_top}>
        <img src={thunderIcon} data-icon />
        <span>Aggregator Preference</span>
      </div>
      <div className={styles.line}></div>
      <ul className={styles.aggregators}>
        {aggregatorMeta.map((item) => {
          return (
            <li
              key={item.key}
              className={aggregator === item.key ? styles.selected : ''}
              onClick={() => {
                globalSettingModel.setAggregator(item.key);
                setTimeout(onClose, 250);
              }}
            >
              <img src={item.icon} data-icon />
              <span>{item.title}</span>
              <img src={aggregator === item.key ? radioSelectedIcon : radioIcon} data-icon />
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default memo(SelectSlippage);
