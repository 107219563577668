import type { BestSwapRouterType } from '@hooks/use-swap-route';
import { type FC, memo, useMemo } from 'react';
import { useCoins } from '@hooks/use-coins';
import type { Aggregator } from '@libs/aggregators';
import Block from '@components/block';

const CoinPath: FC<{
  bestRoutes: BestSwapRouterType[] | undefined;
  aggregator: Aggregator | undefined;
  fetchingBestRoute: boolean;
}> = ({ bestRoutes = [], aggregator, fetchingBestRoute }) => {
  const { coins } = useCoins();

  const paths = useMemo(() => {
    const paths: string[] = [];
    for (const { pool } of bestRoutes) {
      if (!pool) {
        return [];
      }
      const coinA = coins.find((coin) => pool.coin_type_a === coin.type);
      const coinB = coins.find((coin) => pool.coin_type_b === coin.type);
      const symbolA = coinA ? coinA.symbol : '';
      const symbolB = coinB ? coinB.symbol : '';
      paths.push(pool.a_to_b ? symbolA : symbolB);
      paths.push(pool.a_to_b ? symbolB : symbolA);
    }
    return [...new Set(paths)];
  }, [bestRoutes, coins]);

  return (
    <>
      <span>Swapping Through</span>
      {fetchingBestRoute && (!aggregator || !bestRoutes) ? (
        <Block />
      ) : (
        <div>{aggregator ? aggregator.getName() : paths.join(' -> ')}</div>
      )}
    </>
  );
};

export default memo(CoinPath);
