import { type FC, memo, useState, useCallback, useEffect } from 'react';
import styles from './index.module.scss';
import SwapSection from './swap-section';
import { useModel } from 'foca';
import { nativeCoinModel } from '@models/native-coin.model';
import SwapExchange from './swap-exchange';
import SwapSubmit from './swap-submit';
import { useSwapRoute } from '@hooks/use-swap-route';
// import RouteInfo from '../swap-route';
import CoinAPrice from './coin-a-price';
import CoinBPrice from './coin-b-price';
import PythPrice from './pyth-price';
import { useCoinTypeMin } from '@hooks/use-coin-type-min';
import { useHash } from 'react-use';
import { findLocationSearch } from '@utils/location-search';
import Back from '@ui/back';
import { useNavigate } from 'react-router-dom';

const Swap: FC = () => {
  const navigate = useNavigate();

  const { coinTypeA, coinTypeB } = useModel(nativeCoinModel);
  const { min } = useCoinTypeMin(coinTypeA);
  const [amount, setAmount] = useState<string>();
  const [amountSpecifiedIsInput, setAmountSpecifiedIsInput] = useState(false);
  const [deepbookActive, _] = useState(true);
  const {
    fetchingBestRoute,
    bestRoute,
    amountIn,
    amountOut,
    insufficientLiquidity,
    isDeepbook,
    transactionBlock,
    // aggregatorRoute,
  } = useSwapRoute(amount, amountSpecifiedIsInput, deepbookActive);
  const hash = useHash();

  const handleTransacted = useCallback(() => {
    setAmount(undefined);
    // TO clear both input value
    setAmountSpecifiedIsInput((prev) => !prev);
  }, []);

  const handleSetInput = useCallback((value?: string) => {
    setAmount(value);
    setAmountSpecifiedIsInput(true);
  }, []);

  const handleSetOutput = useCallback((value?: string) => {
    setAmount(value);
    setAmountSpecifiedIsInput(false);
  }, []);

  const handleExchange = useCallback(() => {
    if (deepbookActive) {
      setAmount(undefined);
    }
    nativeCoinModel.exchange();
    setAmountSpecifiedIsInput((prev) => !prev);
  }, [deepbookActive]);

  useEffect(() => {
    const _hash = hash[0].replace(/.*\?/, '');
    const input = findLocationSearch(_hash, 'input');
    const output = findLocationSearch(_hash, 'output');

    input && nativeCoinModel.updateA(input);
    output && nativeCoinModel.updateB(output);
  }, [hash[0]]);

  return (
    <div className={styles.wrapper}>
      <Back message="Trade" onClick={() => navigate('/trade')} className={styles.back} />
      <SwapSection
        label="From"
        coinType={coinTypeA}
        onSelectCoin={nativeCoinModel.updateA}
        disableCoins={coinTypeB}
        showMaxAmount={true}
        amount={amountIn}
        inputByUser={amountSpecifiedIsInput}
        onChangeValue={handleSetInput}
        deepbookActive={deepbookActive}
        onChangeAmount={setAmount}
      />
      <div className={styles.price_row}>
        <PythPrice coinType={coinTypeA} />
        {deepbookActive ? (
          <div className={styles.min}>Min amount: {min}</div>
        ) : (
          <CoinAPrice
            bestRoutes={bestRoute}
            amountIn={isDeepbook ? undefined : amountIn}
            className={styles.coin_price}
          />
        )}
      </div>

      <SwapExchange onExchange={handleExchange} />
      <SwapSection
        label="To"
        coinType={coinTypeB}
        onSelectCoin={nativeCoinModel.updateB}
        disableCoins={coinTypeA}
        showMaxAmount={false}
        amount={amountOut}
        inputByUser={!amountSpecifiedIsInput}
        onChangeValue={handleSetOutput}
        deepbookActive={deepbookActive}
        inputDisabled={deepbookActive}
        onChangeAmount={setAmount}
      />
      <div className={styles.price_row}>
        <PythPrice coinType={coinTypeB} />
        <CoinBPrice
          bestRoutes={bestRoute}
          amountIn={amountIn}
          amountOut={isDeepbook ? undefined : amountOut}
          className={styles.coin_price}
        />
      </div>
      <SwapSubmit
        bestRoutes={bestRoute}
        fetchingBestRoute={fetchingBestRoute}
        amountSpecifiedIsInput={amountSpecifiedIsInput}
        insufficientLiquidity={insufficientLiquidity}
        amountIn={amountIn}
        amountOut={amountOut}
        isDeepbook={isDeepbook || deepbookActive}
        transactionBlock={transactionBlock}
        onTransacted={handleTransacted}
      />
      {/* <RouteInfo
        amountIn={amountIn}
        amountOut={amountOut}
        bestRoutes={bestRoute}
        isDeepbook={deepbookActive}
        amountSpecifiedIsInput={amountSpecifiedIsInput}
        aggregatorRoute={aggregatorRoute}
      /> */}
    </div>
  );
};

export default memo(Swap);
